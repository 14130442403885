import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class AddressService {

      getAllAddresses() {
            let url = API_URL + 'user/addresses'
            return apiService.get(url);
      }
      getAddress(value) {
            let url = API_URL + 'user/address/search?key=' + value
            return apiService.query(url);
      }
      paginate(slug, index = null) {
            let url = API_URL + 'user/organisation/' + slug + '/address';
            if (index != null)
                  url = url + '?page=' + index
            return apiService.get(url);
      }

      getStates(city = '', area = '') {
            let url = `/user/address/state`;
            if (city.length > 0)
                  url = `${url}/${city}`
            if (city.length > 0 && area.length > 0)
                  url = `${url}/${area}`
            return apiService.get(url);
      }
      getCity(states = '', area = '') {
            let url = `/user/address/city`;
            if (states.length > 0)
                  url = `${url}/${states}`
            if (states.length > 0 && area.length > 0)
                  url = `${url}/${area}`
            return apiService.get(url);
      }

      getAreas(states = '', city = '') {
            let url = `/user/address/area`;
            if (states.length > 0)
                  url = `${url}/${states}`
            if (states.length > 0 && city.length > 0)
                  url = `${url}/${city}`
            return apiService.get(url);
      }

}
