import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class CouponService {

    paginate(index = null) {
        let url = API_URL + 'user/coupon';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }

    create(data) {
        let url = API_URL + 'user/coupon'
        return apiService.post(url, data)
    }

    update(id, data) {
        let url = API_URL + `user/coupon/${id}`
        return apiService.put(url, data)
    }

    show(id) {
        let url = API_URL + `user/coupon/${id}`
        return apiService.get(url)
    }

    delete(id) {
        let url = API_URL + `user/coupon/${id}`
        return apiService.delete(url)
    }

}
