<template>
  <v-app>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-12 order-xl-1">
        <!-- <v-btn
          text
          @click="addCoupon"
          outlined
          class="btn btn-lg btn-add-main mb-2"
          style="background:transparent;"
        >Add Coupon</v-btn>-->
        <KTPortlet v-bind:title="'Order Report'">
          <template v-slot:toolbar></template>
          <template v-slot:body>
            <div class="row" v-if="loading">
              <div class="col-md-12 mt-2">
                <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
              </div>
            </div>
            <div class="row" v-else-if="!loading && data && data.length<1">
              <div class="col-md-12 text-center">
                <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                <h5 class="mt-10">Looks like you have not made any sales yet. You can get reports once you sell your first product.</h5>
              </div>
            </div>

            <div class="kt-invoice__body" v-else>
              <div class="kt-invoice__container">
                <v-row>
                  <v-col cols="5">
                    <v-menu
                      ref="menu"
                      v-model="date"
                      :close-on-content-click="false"
                      :return-value.sync="dates"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dates"
                          label="Order start from to end date"
                          prepend-inner-icon="event"
                          readonly
                          dense
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="dates" range dense outline></v-date-picker>
                      <!-- </v-date-picker> -->
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      outlined
                      v-model="filter.name"
                      label="By first or last name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field dense outlined v-model="filter.email" label="By Email"></v-text-field>
                  </v-col>
                  <!-- <v-col cols="3">
                    <v-text-field
                      dense
                      outlined
                      v-model="filter.total_amount"
                      label="By Purchase Amount"
                    ></v-text-field>
                  </v-col>-->
                  <v-col cols="3">
                    <v-select
                      :items="['complete', 'pending', 'declined']"
                      dense
                      outlined
                      v-model="filter.order_status"
                      label="By Order Status"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :items="['complete', 'pending', 'declined']"
                      dense
                      outlined
                      v-model="filter.payment_status"
                      label="By Payment Status"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :items="['paypal', 'stripe', 'khalti', 'esewa', 'cash']"
                      dense
                      outlined
                      v-model="filter.payment_method"
                      label="By Payment Method"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :items="websites"
                      dense
                      outlined
                      v-model="filter.website_id"
                      label="By Website"
                      item-text="title"
                      item-value="id"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :items="states"
                      dense
                      outlined
                      v-model="filter.state"
                      label="By State"
                      item-text="title"
                      item-value="id"
                      @change="getCities"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :items="cities"
                      dense
                      outlined
                      v-model="filter.city"
                      label="By City"
                      item-text="title"
                      item-value="id"
                      @change="getAreas"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :items="areas"
                      dense
                      outlined
                      v-model="filter.area"
                      label="By Area"
                      item-text="title"
                      item-value="id"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-btn color="primary" :loading="loading" outlined @click="filterOrder">Filter</v-btn>
                  </v-col>
                </v-row>
                <b-table
                  hover
                  :items="data"
                  id="my-table"
                  :busy.sync="isBusy"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template
                    v-slot:cell(shipment)="row"
                  >{{row.item.shipment.first_name+' '+row.item.shipment.last_name}}</template>
                  <template
                    v-slot:cell(cart_total)="row"
                  >{{row.item.cart_total.currency+' '+row.item.cart_total.total}}</template>

                  <template v-slot:cell(created_at)="row">
                    <span
                      v-if="row.item.order_status"
                      class="badge badge-success"
                    >{{row.item.created_at}}</span>
                  </template>

                  <template v-slot:cell(order_status)="row">
                    <span
                      v-if="row.item.order_status"
                      class="badge badge-primary"
                    >{{row.item.order_status.toUpperCase()}}</span>
                  </template>
                  <template v-slot:cell(payment_status)="row">
                    <span
                      v-if="row.item.payment_status"
                      class="badge badge-primary"
                    >{{row.item.payment_status.toUpperCase()}}</span>
                  </template>
                  <template v-slot:cell(payment_method)="row">
                    <span
                      v-if="row.item.payment_method"
                      class="badge badge-primary"
                    >{{row.item.payment_method.toUpperCase()}}</span>
                  </template>

                  <template v-slot:cell(discount_amount)="row">
                    <span
                      v-if="row.item.discount_type=='fixed'"
                    >{{row.item.currency}}{{row.item.discount_amount}}</span>
                    <span v-else>{{row.item.discount_amount}}</span>
                  </template>
                  <template v-slot:cell(is_active)="row">
                    <span>
                      <i
                        :class="(row.item.is_active)?'fa fa-check text-success': 'fa fa-ban text-danger'"
                      ></i>
                    </span>
                  </template>
                </b-table>
              </div>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </v-app>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import WebsiteService from "@/services/Websites/WebsiteService";
import CouponService from "@/services/coupon/CouponService";
import OrderService from "@/services/cart/CartService";
import AddressService from "@/services/address/AddressService";

const websiteService = new WebsiteService();
const couponService = new CouponService();
const orderService = new OrderService();
const addressService = new AddressService();

export default {
  components: {
    KTPortlet
  },

  data() {
    return {
      areas: [],
      cities: [],
      states: [],
      loading: true,
      dates: [],
      date: null,
      filter: {},
      isBusy: true,
      currentPage: 1,
      perPage: 10,
      coupons: [],
      websites: [],
      data: [],
      fields: [
        // { key: "id", label: "ID", sortable: true },
        { key: "shipment", label: "Full Name", sortable: true },
        { key: "cart_total", label: "Total", sortable: true },
        { key: "order_status", sortable: true },
        { key: "payment_method", sortable: true },
        { key: "payment_status", sortable: true },
        { key: "created_at",label:"Order Received at", sortable: true }
      ]
    };
  },
  computed: {
    formTitle() {
      return this.edit ? "Update Coupon Code" : "Create Coupon Code";
    }
  },
  mounted() {
    this.getAllWebsite();
    // this.getDefaultDate();
    this.getStates();
  },
  methods: {
    getAllWebsite() {
      websiteService
        .getAll()
        .then(response => {
          this.websites = response.data.data;
          this.loading = false;
        })
        .catch(error => {});
    },
    getDefaultDate() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month.toString().length == 1) month = "0" + month;
      let day = date.getDate() - 7;
      if (day.toString().length == 1) day = "0" + day;
      this.dates.push(year + "-" + month + "-" + day);
    },
    filterOrder() {
      this.isBusy = true;
      this.filter.date = this.dates;
      orderService
        .orderReport(this.filter)
        .then(res => {
          this.data = res.data.data;
          this.loading = false;
          this.isBusy = false;
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.isBusy = false;
        });
    },
    getStates() {
      addressService.getStates().then(res => {
        this.states = res.data;
      });
    },
    getCities() {
      addressService.getCity(this.filter.state).then(res => {
        this.cities = res.data;
      });
    },
    getAreas() {
      addressService.getAreas(this.filter.state, this.filter.city).then(res => {
        this.areas = res.data;
      });
    }
  }
};
</script>
